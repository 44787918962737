import { useDispatch } from "react-redux";
import { showProPopup } from "~/features/proPopupSlice";

interface Props {
    count?: number;
}

export default function FontItemShimmer({ count = 1 }: Props) {
    const dispatch = useDispatch();
    return (
        <>
            {Array.from({ length: count }).map((_, index) => (
                <div key={index} className='shimmer-font-item font-item' title="Pro only content - upgrade to view" onClick={() => dispatch(showProPopup())}>
                    <div className='font-icon' style={{ 'backgroundImage': 'url("/icons/icon-lock.svg")' }}>
                    </div>
                    <div className='font-item-details'>
                        <div className='font-item-title'></div>
                        <div className='font-item-description'></div>
                        <div className='font-item-tag'></div>
                    </div>
                </div>
            ))}
        </>
    );
}