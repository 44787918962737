// app/components/Sidebar.tsx

import React, { useEffect, useRef } from "react";
import { FontList } from "~/components/FontList";
import { Font } from "~/types/fontTypes";

interface Props {
  fontData: Font[];
  authenticated: boolean
  proUser: boolean
  totalFonts: number
}

const Sidebar: React.FC<Props> = ({ fontData, authenticated, proUser, totalFonts }) => {
  const overflowContainerRef: any = useRef(null);

  useEffect(() => {
    const activeElementRef = document.querySelectorAll(
      ".font-item.active"
    )[0] as HTMLElement;

    if (activeElementRef && overflowContainerRef.current) {
      const activeElement = activeElementRef;
      const overflowContainer = overflowContainerRef.current;

      // Calculate the position of the active element relative to the parent container
      const offsetTop = activeElement.offsetTop;
      const containerScrollTop = overflowContainer.scrollTop;
      const containerHeight = overflowContainer.clientHeight;

      // Scroll the parent container to bring the active element into view
      if (
        offsetTop < containerScrollTop ||
        offsetTop > containerScrollTop + containerHeight
      ) {
        overflowContainer.scrollTop = offsetTop;
      }
    }
  }, []);

  return (
    <div className={"sidebar"} ref={overflowContainerRef}>
      <div className={`font-list-wrap`}>
        <FontList fonts={fontData} authenticated={authenticated} proUser={proUser} totalFonts={totalFonts} />
      </div>
    </div>
  );
};

export default Sidebar;
