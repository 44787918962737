import React from 'react';
import FontItemShimmer from './FontItemShimmer';
import { useDispatch } from 'react-redux';
import PaywallButton from './PaywallButton';

interface PaywallProps {
    totalFonts: number
}

const Paywall: React.FC<PaywallProps> = ({ totalFonts }) => {
    // locked card will be shown if total fonts are more than 20, otherwise 3 cards will be shown
    const shimmerToShow = totalFonts > 20 ? totalFonts - 20 : 3;
    return (
        <div>
            <FontItemShimmer count={shimmerToShow} />
            <div className="pro-cta-flow">
                <div className="pro-cta-title">
                    <img src='/icons/diamond.svg' alt="pro" width={36} height={31} />
                    <h4>Upgrade to Pro for Unlimited Access</h4>
                </div>
                <ul className="pro-cta-fetaures">
                    <li>Get access to entire collection</li>
                    <li>Explore unlimited fonts in-uses</li>
                    <li className='soon'>Get access to font pairing</li>
                    <li className='soon'>Showcase your type works</li>
                </ul>
                <div className="pro-cta-plans">
                    <PaywallButton entitlement="PRO" term={0} isAuth className="lifetime-plan">
                        <div className="pro-cta-plan-top">
                            <h5>Believer</h5>
                            <span><strong>$50</strong>/lifetime</span>
                        </div>
                        <p>
                            <img src='/icons/icon-clock.svg' alt="pro" width={13} height={8} />
                            <span>Only for the first 1000 members</span>
                        </p>
                    </PaywallButton>
                    <PaywallButton entitlement="PRO" term={1} isAuth className='monthly-plan'>
                        <div className="pro-cta-plan-top">
                            <h5>Monthly</h5>
                            <span><strong>$10</strong>/month</span>
                        </div>
                        <p>Billed every month</p>
                    </PaywallButton>
                </div>
            </div>
        </div>
    );
};

export default Paywall;