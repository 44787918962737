// app/components/MainCarousel.tsx
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "@remix-run/react";
import { Carousel } from "react-responsive-carousel";
import { Tooltip } from "react-tooltip";
import { Font } from "~/types/fontTypes";

interface MainCarouselProps {
  carouselItems: Font[] | null | undefined;
  page: string;
}

export const MainCarousel: React.FC<MainCarouselProps> = ({
  carouselItems,
  page,
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [images, setImages] = useState(carouselItems || []);
  const [isLoadingImages, setIsLoadingImages] = useState(false);

  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [autoPlaySlider, setAutoPlaySlider] = useState(true);
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const { pathname } = useLocation();

  // shimmer html
  const carouselLoader = (
    <div className="slider-container">
      <div className="main-image-container">
        <div className="carousel-shimmer">
          <div className={`thumbnail-container`}>
            <ul>
              <li className="thumb selected">
                <div className="carousel-item"></div>
              </li>
              <li className="thumb">
                <div className="carousel-item"></div>
              </li>
              <li className="thumb">
                <div className="carousel-item"></div>
              </li>
              <li className="thumb">
                <div className="carousel-item"></div>
              </li>
              <li className="thumb">
                <div className="carousel-item"></div>
              </li>
              <li className="thumb">
                <div className="carousel-item"></div>
              </li>
              <li className="thumb">
                <div className="carousel-item"></div>
              </li>
              <li className="thumb">
                <div className="carousel-item"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  // show the shimmer if no data
  if (!carouselItems) {
    return carouselLoader;
  }

  // image loaded function
  const handleImageLoad = () => {
    setLoadedImagesCount((prevCount) => prevCount + 1);
  };

  // Check if all images are loaded
  useEffect(() => {
    // limiting to 10 images for homepage - to avoid long shimmer view
    if (loadedImagesCount > 10 || loadedImagesCount >= images.length) {
      setAllImagesLoaded(true);
    }
  }, [loadedImagesCount, images.length]);

  useEffect(() => {
    // taking the first 8 images from the data, to load the carousels faster.
    const initialImages = carouselItems?.slice(0, 8) || [];
    setImages(initialImages);
    setIsLoading(false);
  }, []);

  // additional carousel image fetching function
  const loadAdditionalImages = (index: number) => {
    // Detect when the last slide is reached
    if (index === images.length - 1 && !isLoadingImages) {
      setIsLoadingImages(true);
      // Load more images
      const additionalImages = carouselItems.slice(
        images.length,
        images.length + 8
      ); // Load additional 8 images
      setImages((prevImages) => [...prevImages, ...additionalImages]);
      setIsLoadingImages(false);
    }
  };

  // function to generate assets (img / video - TODO)
  const generateImgOrVideoTags = (
    file: any,
    slideType: string,
    currentIndex: number,
    activeSlide: number | undefined,
    alt: string
  ) => {
    return (
      <img
        src={file + (slideType === "thumb" ? "&w=64" : "&w=640")}
        srcSet={`
          ${file + (slideType === "thumb" ? "&w=64" : "&w=480")} 480w,
          ${file + (slideType === "thumb" ? "&w=64" : "&w=580")} 640w,
          ${file + (slideType === "thumb" ? "&w=64" : "&w=800")} 800w,
          ${file + (slideType === "thumb" ? "&w=64" : "&w=1200")} 1200w,
          ${file + (slideType === "thumb" ? "&w=64" : "&w=1600")} 1600w,
        `}
        sizes="(max-width: 480px) 440px,
         (max-width: 640px) 580px,
         (max-width: 800px) 800px,
         (max-width: 1600px) 1200px,
         2000px"
        alt={alt + " font - Fontcert"}
        className={slideType === "thumb" ? "thumb-img" : "main-img"}
        loading={currentIndex < 3 ? "eager" : "lazy"}
        //  fetchPriority={currentIndex < 3 ? "high" : "low"} bug in react 18, fix coming in 19
        width={slideType === "thumb" ? 64 : 1920}
        // onload function to hide shimmer
        onLoad={handleImageLoad}
        key={currentIndex}
      />
    );
  };

  // custom html for thumbs
  const generateThumbs = () => {
    return page === "home"
      ? images.map((item, index) => (
        <div key={index} className="carousel-item">
          {/* Take only the first image */}
          {generateImgOrVideoTags(
            item.assets[0].asset_url, // Take the first image
            "thumb",
            index,
            currentSlideIndex,
            item.name
          )}
        </div>
      ))
      : images
        .flatMap((item) =>
          item.assets.map((asset) => ({ ...asset, name: item.name }))
        )
        .map((imageItem, index) => (
          <div key={index} className="carousel-item">
            {generateImgOrVideoTags(
              imageItem.asset_url,
              "thumb",
              index,
              currentSlideIndex,
              imageItem.name
            )}
          </div>
        ));
  };

  // fullscreen function
  const handleFullscreen = () => {
    const elem = document.documentElement; // Accessing the whole document for fullscreen
    if (!isFullScreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      }
    } else {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    }
  };

  // fullscreen with keyboard
  useEffect(() => {
    const handleKeyDown = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "F11") {
        event.preventDefault();
        handleFullscreen();
      }
      if (event.key === "27") {
        event.preventDefault();
        handleFullscreen();
      }
    };

    const handleKeyUp = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "F11") {
        event.preventDefault();
        handleFullscreen();
      }
      if (event.key === "27") {
        event.preventDefault();
        handleFullscreen();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // fullscreen toggle state and body class
  useEffect(() => {
    const fullscreenChangeHandler = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);

    if (isFullScreen) {
      document.body.classList.add("full-screen");
    } else {
      document.body.classList.remove("full-screen");
    }

    return () => {
      document.removeEventListener("fullscreenchange", fullscreenChangeHandler);
    };
  }, [isFullScreen]);

  // tooltip text for price
  const priceTooltip =
    carouselItems[0].price === "free" ? "Get Font" : "Get Trial";

  // more info tooltip
  const infoTooltip = page === "home" ? `More Info` : priceTooltip;

  return isLoading ? (
    carouselLoader
  ) : (
    <>
      {/* if images are loading, we will show the loader */}
      {!allImagesLoaded && carouselLoader}
      <div
        className="slider-container"
        style={{
          opacity: allImagesLoaded ? 1 : 0,
          visibility: allImagesLoaded ? "visible" : "hidden",
        }}
      >
        <div className="main-image-container">

          <button type="button" className="play-pause-button"
            onClick={() => setAutoPlaySlider(!autoPlaySlider)}
            data-tooltip-id="play-pause-tooltip"
            data-tooltip-content={
              autoPlaySlider ? "Pause" : "Play"
            }
          >
            {autoPlaySlider ?
              <img src="/icons/icon-pause.svg" alt="pause" width={16} height={16} /> :
              <img src="/icons/icon-play.svg" alt="play" width={16} height={16} />
            }
          </button>
          <div className="font-link">
            <Tooltip id="play-pause-tooltip" />
            <Tooltip id="info-tooltip" />
            <Tooltip id="fullscreen-tooltip" />
            <Link
              to={
                page === "home"
                  ? "/fonts/" + carouselItems[currentSlideIndex].slug
                  : carouselItems[0].download_link || "#"
              }
              data-tooltip-id="info-tooltip"
              data-tooltip-content={infoTooltip}
              className="link-item btn"
              target={page === "home" ? "_self" : "_blank"}
            >
              <img src="/icons/icon-info.svg" alt="more info" width={16} height={16} />
            </Link>
            <button
              type="button"
              onClick={handleFullscreen}
              className="link-item btn"
              data-tooltip-id="fullscreen-tooltip"
              data-tooltip-content={
                isFullScreen ? "Exit fullscreen mode" : "Play in fullscreen"
              }
            >
              {isFullScreen ? (
                <img src="/icons/icon-grid.svg" alt="exit full screen view" width={16} height={16} />
              ) : (
                <img
                  src="/icons/icon-full-screen.svg"
                  alt="go full screen view"
                  width={16} height={16}
                />
              )}
            </button>
          </div>

          {page === "font" && (
            <div className="user-link-wrap">
              <div className="user-link">
                <span title="added by Sanal">
                  <a
                    href="https://dribbble.com/sanalck"
                    rel="nofollow noreferrer"
                    target="_blank"
                    title="view website"
                  >
                    <img
                      className="link-img"
                      src="/icons/link-icon.svg"
                      alt="link"
                      loading="lazy"
                      width={16}
                      height={16}
                    />
                  </a>
                  <img
                    className="user-img"
                    src="/images/user.png"
                    alt="user"
                    loading="lazy"
                    width={28}
                    height={28}
                  />
                </span>
              </div>
            </div>
          )}

          <Carousel
            className={autoPlaySlider ? "auto-playing" : "auto-paused"}
            autoPlay={allImagesLoaded && autoPlaySlider}
            autoFocus={allImagesLoaded}
            onChange={(index) => {
              setCurrentSlideIndex(index);
              loadAdditionalImages(index);
              // stopVideo();
              // playVideo("video-" + index);
            }}
            onClickThumb={() => {
              if (!autoPlaySlider) {
                setAutoPlaySlider(true);
              }
            }}
            showIndicators={false}
            showArrows={true}
            showStatus={false}
            showThumbs={true}
            selectedItem={0}
            key={pathname}
            interval={3000}
            transitionTime={1000}
            thumbWidth={68}
            animationHandler={`fade`}
            infiniteLoop={currentSlideIndex > 2 ? true : false}
            stopOnHover={false}
            renderThumbs={generateThumbs}
            useKeyboardArrows
            emulateTouch={false}
            swipeable={false}
          >
            {page === "home"
              ? images.map((item, index) => (
                <div key={index} className="carousel-item">
                  {/* Take only the first image */}
                  {generateImgOrVideoTags(
                    item.assets[0].asset_url, // Take the first image
                    "main",
                    index,
                    currentSlideIndex,
                    item.name
                  )}
                </div>
              ))
              : images
                .flatMap((item) =>
                  item.assets.map((asset) => ({ ...asset, name: item.name }))
                )
                .map((imageItem, index) =>
                  generateImgOrVideoTags(
                    imageItem.asset_url,
                    "main",
                    index,
                    currentSlideIndex,
                    imageItem.name
                  )
                )}
          </Carousel>
        </div>
      </div>
    </>
  );
};
